@media (max-width: 1360px) {
    
    header {
        position: absolute; 
        top: 0;
        left: 0;
        width: 100%;
        height: 70px;
        font-family: 'Poppins', sans-serif;
        z-index: 100;
    }

    header ul {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0px 20px;
        display: flex;
        justify-content: end;
        align-items: center;
        color: #333;
    }

    header ul .space {
        display: none;
    }

    header ul .open-menu {
        font-size: 29px;
        cursor: pointer;
        display: block;
    }
    
    header ul .container-nav {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        top: 0;
        right: -100%;
        width: 250px;
        height: 100%;
        background-color: #333;
        transition: right 0.3s ease;
        z-index: 999;
    }
    
    header ul .container-nav.open {
        right: 0;
    }

    header ul .container-close {
        width: 100%;
        height: 70px;
        padding: 0px 20px;
        color: #ffffff;
        display: flex;
        justify-content: end;
        align-items: center;
    }

    header ul .container-close .close-menu {
        font-size: 29px;
        cursor: pointer;
        display: block;
    }

    header ul li {
        position: relative;
        width: 193px;
        margin: 15px 0;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    header ul li span {
        position: relative;
        display: inline-block; 
        text-align: center;
        cursor: pointer;
    }

    header ul li span::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 2px;
        background-color: #333;
        transition: all 0.3s ease; 
        transform: translateX(-50%);
    }

    header ul li span:hover::after {
        width: 100%; 
    }

    header ul .order-online {
        padding: 8px 10px;
        border-radius: 10px;
        color: #ffffff;
        background-color: #e1af56;
        cursor: pointer;
        transition: background-color .3s;
    }

    header ul .order-online:hover {
        background-color: rgb(245, 194, 106);
    }
}

@media (min-width: 1360px) {

    header {
        position: absolute; 
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        font-family: 'Poppins', sans-serif;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        z-index: 100;
    }

    header ul {
        max-width: 1200px;
        height: auto;
        margin: 0 auto;
        padding: 0px 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333;
    }

    header ul .container-close,
    header ul .open-menu {
        display: none;
    }

    header ul .space {
        width: 100px;
        height: 80px;
    }

    header ul .container-nav {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    header ul li {
        position: relative;
        width: 193px;
        font-size: 16px;
        font-weight: 500;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    header ul li span {
        position: relative;
        display: inline-block; 
        text-align: center;
        cursor: pointer;
    }

    header ul li span::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 2px;
        background-color: #333;
        transition: all 0.3s ease; 
        transform: translateX(-50%);
    }

    header ul li span:hover::after {
        width: 100%; 
    }

    header ul .order-online {
        padding: 8px 10px;
        border-radius: 10px;
        color: #ffffff;
        background-color: #e1af56;
        cursor: pointer;
        transition: background-color .3s;
    }

    header ul .order-online:hover {
        background-color: rgb(245, 194, 106);
    }
}