@media (max-width: 700px) {
    

    footer {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;
        background-color: #0e0e0e;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    footer .container-top {
        font-size: 16px;
        font-weight: 700;
        font-style: italic;
        text-align: center;
        display: flex;
        flex-direction: column;
        color: #ffffff;
        padding: 20px 60px;
        margin: 50px 0;
        max-width: 900px;
        width: 100%;
        height: 50  0px;
    }

    footer .container-top h1 {
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        margin-bottom: 25px;
    }

    footer .container-top .box:nth-child(1) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
    }

    footer .container-top .box:nth-child(1) span {
        text-align: center;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 10px;
    }

    footer .container-top .box:nth-child(1) a {
        text-align: center;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 10px;
    }

    footer .container-top .box:nth-child(1) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-top .box:nth-child(2) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
    }

    footer .container-top .box:nth-child(2) .container-hours {
        width: 200px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 10px;
        font-weight: 400;
    }

    footer .container-top .box:nth-child(2) .container-hours .hours {
        width: 100px;
        display: flex;
        align-items: end;
        flex-direction: column;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(3) {
        height: auto;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(3) div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }

    footer .container-top .box:nth-child(3) span {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(3) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-center {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: center;
        color: #ffffff;
        border-top: 1px solid #b8b8b893;
        padding: 20px;
        margin-top: 30px;
        max-width: 1320px;
        width: 80%;
    }

    footer .container-bottom {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: right;
        color: #ffffff;
        background-color: #000000;
        padding: 12.5px 50px;
        width: 100%;
    }
}

@media (min-width: 700px) and (max-width: 1200px) {
    footer {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;
        background-color: #0e0e0e;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    footer .container-top {
        font-size: 16px;
        font-weight: 700;
        font-style: italic;
        text-align: center;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        color: #ffffff;
        padding: 20px 60px;
        margin: 50px 0;
        max-width: 700px;
        width: 100%;
        height: auto;
    }

    footer .container-top .box {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    footer .container-top .box:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        order: 3;
        align-self: center;
        flex: 1 1 100%;
        margin-top: 20px; 
    }
    
    footer .container-top .box:nth-child(1) {
        margin-right: 10px;
    }

    footer .container-top .box:nth-child(3) {
        margin-left: 10px;
    }

    footer .container-top h1 {
        text-align: start;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        margin-bottom: 25px;
    }

    footer .container-top .box:nth-child(1) span,
    footer .container-top .box:nth-child(1) a,
    footer .container-top .box:nth-child(2) span,
    footer .container-top .box:nth-child(3) span {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 10px;
    }

    footer .container-top .box:nth-child(1) div .icon-contact,
    footer .container-top .box:nth-child(3) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-top .box:nth-child(2) .container-hours {
        width: 200px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 10px;
        font-weight: 400;
    }

    footer .container-top .box:nth-child(2) .container-hours .hours {
        width: 100px;
        display: flex;
        align-items: start;
        flex-direction: column;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(3) div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }

    footer .container-center {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: center;
        color: #ffffff;
        border-top: 1px solid #b8b8b893;
        padding: 20px;
        margin-top: 30px;
        max-width: 1320px;
        width: 100%;
    }

    footer .container-bottom {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: right;
        color: #ffffff;
        background-color: #000000;
        padding: 12.5px 50px;
        width: 100%;
    }
}

@media (min-width: 1200px) and (max-width: 1440px) {

    footer {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;
        background-color: #0e0e0e;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    footer .container-top {
        font-size: 16px;
        font-weight: 700;
        font-style: italic;
        text-align: center;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        color: #ffffff;
        padding: 20px 60px;
        margin: 50px 0;
        max-width: 1000px;
        width: 100%;
        height: 200px;
    }

    footer .container-top h1 {
        text-align: start;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        margin-bottom: 25px;
    }

    footer .container-top .box:nth-child(1) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(1) span {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 10px;
    }

    footer .container-top .box:nth-child(1) a {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 10px;
    }

    footer a {
        cursor: pointer; 
    }

    footer .container-top .box:nth-child(1) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-top .box:nth-child(2) {
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(2) .container-hours {
        width: 200px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 10px;
        font-weight: 400;
    }

    footer .container-top .box:nth-child(2) .container-hours .hours {
        width: 100px;
        display: flex;
        align-items: start;
        flex-direction: column;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(3) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(3) div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }

    footer .container-top .box:nth-child(3) span {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(3) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-center {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: center;
        color: #ffffff;
        border-top: 1px solid #b8b8b893;
        padding: 20px;
        margin-top: 30px;
        max-width: 1320px;
        width: 100%;
    }

    footer .container-bottom {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: right;
        color: #ffffff;
        background-color: #000000;
        padding: 12.5px 50px;
        width: 100%;
    }
}

@media (min-width: 1440px) {

    footer {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;
        background-color: #0e0e0e;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    footer .container-top {
        font-size: 16px;
        font-weight: 700;
        font-style: italic;
        text-align: center;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        color: #ffffff;
        padding: 20px 60px;
        margin: 50px 0;
        max-width: 1000px;
        width: 100%;
        height: 200px;
    }

    footer .container-top h1 {
        text-align: start;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        margin-bottom: 25px;
    }

    footer .container-top .box:nth-child(1) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(1) span {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 10px;
    }

    footer .container-top .box:nth-child(1) a {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 10px;
    }

    footer a {
        cursor: pointer; 
    }

    footer .container-top .box:nth-child(1) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-top .box:nth-child(2) {
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(2) .container-hours {
        width: 200px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 10px;
        font-weight: 400;
    }

    footer .container-top .box:nth-child(2) .container-hours .hours {
        width: 100px;
        display: flex;
        align-items: start;
        flex-direction: column;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(3) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(3) div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }

    footer .container-top .box:nth-child(3) span {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(3) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-center {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: center;
        color: #ffffff;
        border-top: 1px solid #b8b8b893;
        padding: 20px;
        margin-top: 30px;
        max-width: 1320px;
        width: 100%;
    }

    footer .container-bottom {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: right;
        color: #ffffff;
        background-color: #000000;
        padding: 12.5px 50px;
        width: 100%;
    }
}