@keyframes scale-in {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scale-out {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0);
        opacity: 0;
    }
}

@media (max-width: 660px) {

    .background-menu {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        backdrop-filter: blur(10px);
        background-color: rgba(0, 0, 0, 0.384);
        z-index: 9999;
    }
    
    .background-menu .dialog-menu {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 20px;
        background-color: #ffffff;
        font-family: 'Poppins', sans-serif;
        color: #333;
        animation: scale-in 0.3s ease-out; 
        overflow-y: scroll;
        z-index: 9999;
    }
    
    .background-menu .dialog-menu.closing {
        animation: scale-out 0.3s ease-out; 
    }
    
    .background-menu .dialog-menu h1 {
        margin-top: 55px;
        font-size: 35px;
        font-weight: 600;
        text-align: center;
    }
    
    .background-menu .dialog-menu h5 {
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
    }
    
    .background-menu .dialog-menu h5 span {
        font-style: italic;
        font-size: 23px;
        font-weight: 600;
    }
    
    .background-menu .dialog-menu .close {  
        position: absolute;
        padding:  5px 10px;
        border-radius: 50%;
        top: 20px;
        right: 20px;
        font-size: 30px;
        cursor: pointer;
        transition: background-color .3s;
    }
    
    .background-menu .dialog-menu .close:hover {
        background-color: rgb(224, 224, 224);
    }  
    
    .background-menu .dialog-menu .container-entrance {
        width: 90%;
        margin: 75px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-style: italic;
    }
    
    .background-menu .dialog-menu .container-entrance h2 {
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 15px;
    }
    
    .background-menu .dialog-menu .container-entrance p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    
    .background-menu .dialog-menu .container-main {
        width: 90%;
        margin: 75px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-style: italic;
    }
    
    .background-menu .dialog-menu .container-main h3 {
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 15px;
    }
    
    .background-menu .dialog-menu .container-main p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    
    .background-menu .dialog-menu .container-accompaniement {
        width: 90%;
        margin: 75px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-style: italic;
    }
    
    .background-menu .dialog-menu .container-accompaniement h4 {
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 15px;
    }
    
    .background-menu .dialog-menu .container-accompaniement p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    
    .background-menu .dialog-menu .container-btn {
        width: 100%;
        height: auto;
        margin: 100px 0px 50px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-menu .dialog-menu .container-btn button {
        width: 230px;
        height: 40px;
        border-radius: 5px;
        color: #ffffff;
        background-color: #e1af56;
        font-size: 16px;
        font-weight: 700;
        border: none;
        cursor: pointer;
        outline: none;
        transition: background-color .3s;
    }
    
    .background-menu .dialog-menu .container-btn button:hover {
        background-color: rgb(245, 194, 106);
    }
}

@media (min-width: 660px) {

    .background-menu {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        backdrop-filter: blur(10px);
        background-color: rgba(0, 0, 0, 0.384);
        z-index: 9999;
        overflow-y: scroll;
    }

    .background-menu .dialog-menu {
        position: relative;
        max-width: 650px;
        height: auto;
        margin: 100px auto;
        padding: 20px;
        background-color: #ffffff;
        border: 2px solid #e1af56;
        font-family: 'Poppins', sans-serif;
        color: #333;
        border-radius: 10px;
        animation: scale-in 0.3s ease-out; 
    }
    
    .background-menu .dialog-menu.closing {
        animation: scale-out 0.3s ease-out; 
    }
    
    .background-menu .dialog-menu h1 {
        margin-top: 50px;
        font-size: 35px;
        font-weight: 600;
        text-align: center;
    }
    
    .background-menu .dialog-menu h5 {
        margin-bottom: 30px;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
    }
    
    .background-menu .dialog-menu h5 span {
        font-style: italic;
        font-size: 25px;
        font-weight: 600;
    }
    
    .background-menu .dialog-menu .close {  
        position: absolute;
        padding:  5px 10px;
        border-radius: 50%;
        top: 20px;
        right: 20px;
        font-size: 30px;
        cursor: pointer;
        transition: background-color .3s;
    }
    
    .background-menu .dialog-menu .close:hover {
        background-color: rgb(224, 224, 224);
    }  
    
    .background-menu .dialog-menu .container-entrance {
        width: 90%;
        margin: 75px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-style: italic;
    }
    
    .background-menu .dialog-menu .container-entrance h2 {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    
    .background-menu .dialog-menu .container-entrance p {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    
    .background-menu .dialog-menu .container-main {
        width: 90%;
        margin: 75px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-style: italic;
    }
    
    .background-menu .dialog-menu .container-main h3 {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    
    .background-menu .dialog-menu .container-main p {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    
    .background-menu .dialog-menu .container-accompaniement {
        width: 90%;
        margin: 75px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-style: italic;
    }
    
    .background-menu .dialog-menu .container-accompaniement h4 {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    
    .background-menu .dialog-menu .container-accompaniement p {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    
    .background-menu .dialog-menu .container-btn {
        width: 100%;
        height: auto;
        margin-top: 100px;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    .background-menu .dialog-menu .container-btn button {
        width: 250px;
        height: 45px;
        border-radius: 5px;
        color: #ffffff;
        background-color: #e1af56;
        font-size: 18px;
        font-weight: 700;
        border: none;
        cursor: pointer;
        outline: none;
        transition: background-color .3s;
    }
    
    .background-menu .dialog-menu .container-btn button:hover {
        background-color: rgb(245, 194, 106);
    }
}
