.background-connection {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-connection .container-connection {
    width: 320px;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    color: #333;
    font-family: 'Poppins', sans-serif;
    background-color: #ffffff;
    border: 1px solid #cfcfcf;
}

.background-connection .container-connection h1 {
    text-align: center;
    font-size: 25px;
    font-weight: 700;
}

.background-connection .container-connection p {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 30px;
}

.background-connection .container-connection .input-box {
    width: 100%;
    height: auto;
    margin: 5px 0;
    display: flex;
    flex-direction: column;
}

.background-connection .container-connection .input-box label {
    font-size: 14px;
    font-weight: 700;
}

.background-connection .container-connection .input-box input {
    width: 100%;
    height: 35px;
    padding: 10px;
    border-radius: 6px;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #adadad;
}

.background-connection .container-connection .container-btn {
    width: 100%;
    height: auto;
    margin-top: 40px;
    display: flex;
    justify-content: end;
}

.background-connection .container-connection .container-btn button {
    width: 120px;
    height: 35px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    background-color: #333;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.background-connection .container-connection .container-btn button:hover {
    background-color: #4b4b4b;
}