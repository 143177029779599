@media (max-width: 650px) {

    .background-home .container-home {
        position: relative;
        width: 100%;
        height: 50vh;
        min-height: 540px;
        max-height: 635px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
        z-index: 1;
    }

    .background-home .container-home .space {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 70px;
        background-color: #ffffff;
        z-index: 5; 
    }

    .background-home .container-home .background-color {
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        height: calc(100% - 70px); 
        background-color: #000000;
        z-index: 5; 
    }

    .background-home .container-home img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 5; 
    }

    .background-home .container-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-wrapper .wrapper {
        position: relative;
        width: 550px;
        height: 400px;
        margin: 80px 20px 0px 20px;
        color: #ffffff;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-wrapper .wrapper h4 {
        font-family: 'Poppins', sans-serif;
        font-size: 28px;
        font-weight: 500;
        z-index: 5;
    }

    .background-home .container-wrapper .wrapper h1 {
        font-family: 'Embed Code', serif;
        font-size: 35px;
        font-weight: 600;
        z-index: 5;
    }

    .background-home .container-wrapper .wrapper p {
        width: 275px;
        margin-top: 10px;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 400;
        z-index: 5;
    }

    .background-home .container-wrapper .wrapper button {
        width: 210px;
        height: 40px;
        margin-top: 20px;
        background: transparent;
        border: 2px solid #e1af56;
        border-radius: 10px;
        color: #e1af56;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
        z-index: 5;
        transition: border-color 0.3s, color 0.3s;
    }

    .background-home .container-wrapper .wrapper .order-online {
        margin-top: 10px;
        color: #000000;
        background-color: #e1af56;
    }
    
    .background-home .container-wrapper .wrapper button:hover {
        border: 2px solid rgb(245, 194, 106);
        color: rgb(245, 194, 106);
    }

    .background-home .container-wrapper .wrapper .order-online:hover {
        color: #000000;
        background-color: rgb(245, 194, 106);
    }

    .slick-list {
        overflow: hidden;
        width: 100%;
        max-width: 302px;
    }
    
    .slick-track {
        display: flex;
    }

    .background-home .container-box {
        width: 100%;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #f3f3f3;
    }

    .container-box-center {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        margin-bottom: 40px;
    }

    .background-home .container-box .box {
        width: 312px;
        height: 450px;
        padding: 40px 10px;
        border-right: 1px solid #cfcfcf;
        border-left: 1px solid #cfcfcf;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .background-home .container-box .box .container-icon {
        margin: 0px auto 20px auto;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-box .box .container-icon .icon {
        font-size: 50px;
    }

    .background-home .container-box .box h3 {
        height: 60px;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-box .box .container-img {
        width: 100%;
        height: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-box .box .container-img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .background-home .container-box .container-box-end {
        width: 100%;
        background-color: rgb(235, 235, 235);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
    }


    .background-home .container-box .box-left-right {
        width: 100px;
        margin: 50px 0;
    }

    .background-home .container-box .box-left-right h3 {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
        text-align: center;
    }

    .background-home .container-box .box-left-right p {
        font-family: 'Poppins', sans-serif;
        margin: 0 auto;
        width: 200px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
    }

    .background-home .container-box .box-left-right .container-icon {
        width: 125px;
        height: 125px;
        margin: 0 auto 30px auto;
        border: 3px solid #333;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-box .box-left-right .container-icon .icon {
        font-size: 48px;
    }
}

@media (min-width: 650px) and (max-width: 1000px) {

    .background-home .container-home {
        position: relative;
        width: 100%;
        height: 64vh;
        min-height: 650px;
        max-height: 1080px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
        z-index: 1;
    }
    
    .background-home .container-home .space {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background-color: #ffffff;
        z-index: 5; 
    }
    
    .background-home .container-home .background-color {
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        height: calc(100% - 80px); 
        background-color: #000000;
        z-index: 5; 
    }
    
    .background-home .container-home img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 5; 
    }
    
    .background-home .container-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-wrapper .wrapper {
        position: relative;
        width: 550px;
        height: 400px;
        margin: 80px 20px 0px 20px;
        color: #ffffff;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-wrapper .wrapper h4 {
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
        font-weight: 500;
        z-index: 5;
    }
    
    .background-home .container-wrapper .wrapper h1 {
        font-family: 'Embed Code', serif;
        font-size: 50px;
        font-weight: 600;
        z-index: 5;
    }
    
    .background-home .container-wrapper .wrapper p {
        width: 400px;
        margin-top: 10px;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        z-index: 5;
    }
    
    .background-home .container-wrapper .wrapper button {
        width: 230px;
        height: 40px;
        margin-top: 40px;
        background: transparent;
        border: 2px solid #e1af56;
        border-radius: 10px;
        color: #e1af56;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        z-index: 5;
        transition: border-color 0.3s, color 0.3s;
    }

    .background-home .container-wrapper .wrapper .order-online {
        margin-top: 15px;
        color: #000000;
        background-color: #e1af56;
    }
    
    .background-home .container-wrapper .wrapper button:hover {
        border: 2px solid rgb(245, 194, 106);
        color: rgb(245, 194, 106);
    }

    .background-home .container-wrapper .wrapper .order-online:hover {
        color: #000000;
        background-color: rgb(245, 194, 106);
    }

    .slick-list {
        overflow: hidden;
        width: 100%;
        max-width: 600px;
    }
    
    .slick-track {
        display: flex;
    }

    .background-home .container-box {
        width: 100%;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #f3f3f3;
    }

    .container-box-center {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        margin-bottom: 40px;
    }

    .background-home .container-box .box {
        width: 312px;
        height: 450px;
        padding: 40px 10px;
        border-right: 1px solid #cfcfcf;
        border-left: 1px solid #cfcfcf;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .background-home .container-box .box .container-icon {
        margin: 0px auto 20px auto;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-box .box .container-icon .icon {
        font-size: 50px;
    }

    .background-home .container-box .box h3 {
        height: 60px;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-box .box .container-img {
        width: 100%;
        height: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-box .box .container-img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .background-home .container-box .container-box-end {
        width: 100%;
        padding: 30px 0px;
        background-color: rgb(235, 235, 235);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
    }

    .background-home .container-box .box-left,
    .background-home .container-box .box-right {
        width: 300px;
        margin: 25px 40px;
    }

    .background-home .container-box .box-left h3,
    .background-home .container-box .box-right h3 {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
        text-align: center;
    }

    .background-home .container-box .box-left p,
    .background-home .container-box .box-right p {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
    }

    .background-home .container-box .box-left .container-icon,
    .background-home .container-box .box-right .container-icon {
        width: 125px;
        height: 125px;
        margin: 0 auto 30px auto;
        border: 3px solid #333;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-box .box-left .container-icon .icon {
        font-size: 48px;
    }

    .background-home .container-box .box-right .container-icon .icon {
        font-size: 45px;
    }
}

@media (min-width: 1000px) and (max-width: 1440px) {

    .background-home .container-home {
        position: relative;
        width: 100%;
        height: 64vh;
        min-height: 650px;
        max-height: 1080px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
        z-index: 1;
    }
    
    .background-home .container-home .space {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background-color: #ffffff;
        z-index: 5; 
    }
    
    .background-home .container-home .background-color {
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        height: calc(100% - 80px); 
        background-color: #000000;
        z-index: 5; 
    }
    
    .background-home .container-home img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 5; 
    }
    
    .background-home .container-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-wrapper .wrapper {
        position: relative;
        width: 550px;
        height: 400px;
        margin: 80px 20px 0px 20px;
        color: #ffffff;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-wrapper .wrapper h4 {
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
        font-weight: 500;
        z-index: 5;
    }
    
    .background-home .container-wrapper .wrapper h1 {
        font-family: 'Embed Code', serif;
        font-size: 50px;
        font-weight: 600;
        z-index: 5;
    }
    
    .background-home .container-wrapper .wrapper p {
        width: 400px;
        margin-top: 10px;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        z-index: 5;
    }
    
    .background-home .container-wrapper .wrapper button {
        width: 230px;
        height: 40px;
        margin-top: 40px;
        background: transparent;
        border: 2px solid #e1af56;
        border-radius: 10px;
        color: #e1af56;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        z-index: 5;
        transition: border-color 0.3s, color 0.3s;
    }

    .background-home .container-wrapper .wrapper .order-online {
        margin-top: 15px;
        color: #000000;
        background-color: #e1af56;
    }
    
    .background-home .container-wrapper .wrapper button:hover {
        border: 2px solid rgb(245, 194, 106);
        color: rgb(245, 194, 106);
    }

    .background-home .container-wrapper .wrapper .order-online:hover {
        color: #000000;
        background-color: rgb(245, 194, 106);
    }

    .background-home .container-box {
        width: 100%;
        max-width: 1440px;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #f3f3f3;
    }

    .container-box-center {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        margin-bottom: 40px;
    }

    .background-home .container-box .box {
        width: 352px;
        min-width: 312px;
        height: 450px;
        padding: 40px 10px;
        border-right: 2px solid #cfcfcf;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .background-home .container-box .box:nth-child(2) {
        border-right: none;
    }

    .background-home .container-box .box .container-icon {
        margin: 0px auto 20px auto;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-box .box .container-icon .icon {
        font-size: 50px;
    }

    .background-home .container-box .box h3 {
        height: 60px;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-box .box .container-img {
        width: 100%;
        height: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-box .box .container-img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .background-home .container-box .container-box-end {
        width: 100%;
        padding: 30px 0px;
        background-color: rgb(235, 235, 235);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
    }

    .background-home .container-box .box-left,
    .background-home .container-box .box-right {
        width: 300px;
        margin: 25px 40px;
    }

    .background-home .container-box .box-left h3,
    .background-home .container-box .box-right h3 {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
        text-align: center;
    }

    .background-home .container-box .box-left p,
    .background-home .container-box .box-right p {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
    }

    .background-home .container-box .box-left .container-icon,
    .background-home .container-box .box-right .container-icon {
        width: 125px;
        height: 125px;
        margin: 0 auto 30px auto;
        border: 3px solid #333;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-box .box-left .container-icon .icon {
        font-size: 48px;
    }

    .background-home .container-box .box-right .container-icon .icon {
        font-size: 45px;
    }
}


@media (min-width: 1440px) {

    .background-home .container-home {
        position: relative;
        width: 100%;
        height: 64vh;
        min-height: 650px;
        max-height: 1080px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
        z-index: 1;
    }
    
    .background-home .container-home .space {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background-color: #ffffff;
        z-index: 5; 
    }
    
    .background-home .container-home .background-color {
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        height: calc(100% - 80px); 
        background-color: #000000;
        z-index: 5; 
    }
    
    .background-home .container-home img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 5; 
    }
    
    .background-home .container-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-wrapper .wrapper {
        position: relative;
        width: 550px;
        height: 400px;
        margin: 80px 20px 0px 20px;
        color: #ffffff;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-wrapper .wrapper h4 {
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
        font-weight: 500;
        z-index: 5;
    }
    
    .background-home .container-wrapper .wrapper h1 {
        font-family: 'Embed Code', serif;
        font-size: 50px;
        font-weight: 600;
        z-index: 5;
    }
    
    .background-home .container-wrapper .wrapper p {
        width: 400px;
        margin-top: 10px;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        z-index: 5;
    }
    
    .background-home .container-wrapper .wrapper button {
        width: 230px;
        height: 40px;
        margin-top: 40px;
        background: transparent;
        border: 2px solid #e1af56;
        border-radius: 10px;
        color: #e1af56;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        z-index: 5;
        transition: border-color 0.3s, color 0.3s;
    }

    .background-home .container-wrapper .wrapper .order-online {
        margin-top: 15px;
        color: #000000;
        background-color: #e1af56;
    }
    
    .background-home .container-wrapper .wrapper button:hover {
        border: 2px solid rgb(245, 194, 106);
        color: rgb(245, 194, 106);
    }

    .background-home .container-wrapper .wrapper .order-online:hover {
        color: #000000;
        background-color: rgb(245, 194, 106);
    }

    .background-home .container-box {
        width: 100%;
        max-width: 1200px;
        padding: 0px 40px;
        margin: 50px auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #f3f3f3;
    }

    .container-box-center {
        width: 55%;
        display: flex;
        flex-direction: row;
    }

    .background-home .container-box .box {
        width: 50%;
        height: 450px;
        padding: 40px 10px;
        border-right: 2px solid #cfcfcf;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .background-home .container-box .box:nth-child(2) {
        border-right: none;
    }

    .background-home .container-box .box .container-icon {
        margin: 0px auto 20px auto;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-box .box .container-icon .icon {
        font-size: 50px;
    }

    .background-home .container-box .box h3 {
        height: 60px;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-box .box .container-img {
        width: 100%;
        height: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-box .box .container-img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .background-home .container-box .box-left,
    .background-home .container-box .box-right {
        width: 18%;
        margin-top: 25px;
        cursor: pointer;
    }

    .background-home .container-box .box-left h3,
    .background-home .container-box .box-right h3 {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
        text-align: center;
    }

    .background-home .container-box .box-left p,
    .background-home .container-box .box-right p {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
    }

    .background-home .container-box .box-left .container-icon,
    .background-home .container-box .box-right .container-icon {
        width: 125px;
        height: 125px;
        margin: 0 auto 30px auto;
        border: 3px solid #333;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .background-home .container-box .box-left .container-icon .icon {
        font-size: 48px;
    }

    .background-home .container-box .box-right .container-icon .icon {
        font-size: 45px;
    }
}
