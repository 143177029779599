@media (min-width: 350px) and (max-width: 660px) {

    .background-menumanagement {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        backdrop-filter: blur(10px);
        background-color: rgba(0, 0, 0, 0.384);
    }
    
    .background-menumanagement .dialog-menumanagement {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        font-family: 'Poppins', sans-serif;
        color: #333;
        animation: scale-in 0.3s ease-out; 
        overflow-y: scroll;
    }
    
    .background-menumanagement .dialog-menumanagement.closing {
        animation: scale-out 0.3s ease-out; 
    }
    
    .background-menumanagement .dialog-menumanagement h1 {
        margin-top: 70px;
        font-size: 35px;
        font-weight: 600;
        text-align: center;
    }
    
    .background-menumanagement .dialog-menumanagement h5 {
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
    }
    
    .background-menumanagement .dialog-menumanagement h5 span {
        font-style: italic;
        font-size: 23px;
        font-weight: 600;
    }

    .background-menumanagement .dialog-menumanagement h5 input {
        width: 40px;
        font-style: italic;
        font-size: 25px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        border: none;
        outline: none;
        color: #333;
    }
    
    .background-menumanagement .dialog-menumanagement .close {  
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 30px;
        cursor: pointer;
        transition: background-color .3s;
    }
    
    .background-menumanagement .dialog-menumanagement .container-entrance {
        width: 90%;
        margin: 75px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-style: italic;
    }
    
    .background-menumanagement .dialog-menumanagement .container-entrance h2 {
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 15px;
    }

    .background-menumanagement .dialog-menumanagement .container-entrance input {
        width: 100%;
        margin-bottom: 10px;
        font-style: italic;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 400;
        border: none;
        outline: none;
        color: #333;
    }
    
    .background-menumanagement .dialog-menumanagement .container-main {
        width: 90%;
        margin: 75px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-style: italic;
    }
    
    .background-menumanagement .dialog-menumanagement .container-main h3 {
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 15px;
    }

    .background-menumanagement .dialog-menumanagement .container-main input {
        width: 100%;
        margin-bottom: 10px;
        font-style: italic;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 400;
        border: none;
        outline: none;
        color: #333;
    }
    
    .background-menumanagement .dialog-menumanagement .container-accompaniement {
        width: 90%;
        margin: 75px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-style: italic;
    }
    
    .background-menumanagement .dialog-menumanagement .container-accompaniement h4 {
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 15px;
    }

    .background-menumanagement .dialog-menumanagement .container-accompaniement input {
        width: 100%;
        margin-bottom: 10px;
        font-style: italic;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 400;
        border: none;
        outline: none;
        color: #333;
    }
    
    .background-menumanagement .dialog-menumanagement .container-btn {
        width: 100%;
        height: auto;
        margin: 100px 0px 50px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-menumanagement .dialog-menumanagement .container-btn button {
        width: 230px;
        height: 40px;
        border-radius: 5px;
        color: #ffffff;
        background-color: #e1af56;
        font-size: 16px;
        font-weight: 700;
        border: none;
        cursor: pointer;
        transition: background-color .3s;
    }
    
    .background-menumanagement .dialog-menumanagement .container-btn button:hover {
        background-color: rgb(245, 194, 106);
    }
}

@media (min-width: 660px) {

    .background-menumanagement {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #f0f0f0;
        overflow-y: scroll;
    }

    .background-menumanagement .dialog-menumanagement {
        position: relative;
        max-width: 650px;
        height: auto;
        margin: 100px auto;
        padding: 20px;
        background-color: #ffffff;
        border: 2px solid #e1af56;
        font-family: 'Poppins', sans-serif;
        color: #333;
        border-radius: 10px;
    }

    .background-menumanagement .dialog-menumanagement h1 {
        margin-top: 50px;
        font-size: 35px;
        font-weight: 600;
        text-align: center;
    }

    .background-menumanagement .dialog-menumanagement h5 {
        margin-bottom: 30px;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
    }

    .background-menumanagement .dialog-menumanagement h5 span {
        font-style: italic;
        font-size: 25px;
        font-weight: 600;
    }

    .background-menumanagement .dialog-menumanagement h5 input {
        width: 40px;
        font-style: italic;
        font-size: 25px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        border: none;
        outline: none;
        color: #333;
    }

    .background-menumanagement .dialog-menumanagement .close {  
        position: absolute;
        padding:  10px 10px;
        border-radius: 50%;
        top: 20px;
        right: 20px;
        font-size: 30px;
        cursor: pointer;
        transition: background-color .3s;
    }

    .background-menumanagement .dialog-menumanagement .close:hover {
        background-color: rgb(224, 224, 224);
    }  

    .background-menumanagement .dialog-menumanagement .container-entrance {
        width: 90%;
        margin: 75px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-style: italic;
    }

    .background-menumanagement .dialog-menumanagement .container-entrance h2 {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .background-menumanagement .dialog-menumanagement .container-entrance input {
        width: 100%;
        margin-bottom: 10px;
        font-style: italic;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 400;
        border: none;
        outline: none;
        color: #333;
    }

    .background-menumanagement .dialog-menumanagement .container-main {
        width: 90%;
        margin: 75px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-style: italic;
    }

    .background-menumanagement .dialog-menumanagement .container-main h3 {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .background-menumanagement .dialog-menumanagement .container-main input {
        width: 100%;
        margin-bottom: 10px;
        font-style: italic;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 400;
        border: none;
        outline: none;
        color: #333;
    }

    .background-menumanagement .dialog-menumanagement .container-accompaniement {
        width: 90%;
        margin: 75px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-style: italic;
    }

    .background-menumanagement .dialog-menumanagement .container-accompaniement h4 {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .background-menumanagement .dialog-menumanagement .container-accompaniement input {
        width: 100%;
        margin-bottom: 10px;
        font-style: italic;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 400;
        border: none;
        outline: none;
        color: #333;
    }

    .background-menumanagement .dialog-menumanagement .container-btn {
        width: 100%;
        height: auto;
        margin-top: 100px;
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .background-menumanagement .dialog-menumanagement .container-btn button {
        width: 150px;
        height: 45px;
        border-radius: 5px;
        color: #ffffff;
        background-color: #e1af56;
        font-size: 18px;
        font-weight: 700;
        border: none;
        cursor: pointer;
        transition: background-color .3s;
    }

    .background-menumanagement .dialog-menumanagement .container-btn button:hover {
        background-color: rgb(245, 194, 106);
    }
}