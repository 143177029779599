.grecaptcha-badge {
    visibility: hidden;
}

@media (max-width: 700px) {

    .background-contact {
        width: 100%;
        margin: 50px 0 80px 0;
    }
    
    .container-contact {
        width: 100%;
        padding: 50px 0px;
        height: auto;
        margin: 0 auto;
        color: #333;
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
    
    .container-contact .container-left {
        width: 100%;
        padding: 0 20px;
        height: auto;
        font-family: 'Poppins', sans-serif;
    }
    
    .container-contact .container-left h1 {
        text-align: center;
        font-size: 30px;
        font-weight: 500;
    }
    
    .container-contact .container-left p {
        text-align: center;
        width: 100%;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 20px;
    }
    
    .container-contact .container-left label {
        font-size: 15px;
        font-weight: 600;
    }
    
    .container-contact .container-left input {
        width: 100%;
        height: 35px;
        padding: 10px;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #333;
        border: 1px solid #333;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.089);
    }
    
    .container-contact .container-left textarea {
        width: 100%;
        height: 120px;
        padding: 10px;
        font-size: 15px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #333;
        border: 1px solid #333;
        border-radius: 4px;
        resize: none;
        background-color: rgba(255, 255, 255, 0.089);
    }
    
    .container-contact .container-left .container-1 {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .container-contact .container-left .container-1 .input-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 5px 0;
        height: auto;
    }
    
    .container-contact .container-left .container-2 {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .container-contact .container-left .container-2 .input-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 5px 0;
        height: auto;
    }
    
    .container-contact .container-left .container-3 {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }
    
    .container-contact .container-left .container-3 .input-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 5px 0;
        height: auto;
    }
    
    .container-contact .container-left .container-btn {
        width: 100%;
        margin-top: 40px;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    .container-contact .container-left .container-btn button {
        font-size: 15px;
        font-weight: 600;
        padding: 10px 20px;
        background-color: #e1af56;
        color: #ffffff;
        border: none;
        border-radius: 6px;
        cursor: pointer;
    }
    
    .container-contact .container-left .container-btn button:hover {
        background-color: rgb(245, 194, 106);
    }
    
    .container-right {
        display: none;
    }
    
}

@media (min-width: 700px) and (max-width: 1200px) {

    .background-contact {
        width: 100%;
        margin: 50px 0 80px 0;
    }
    
    .container-contact {
        width: 100%;
        padding: 50px 0px;
        height: auto;
        margin: 0 auto;
        color: #333;
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
    
    .container-contact .container-left {
        width: 80%;
        padding: 0 20px;
        height: auto;
        font-family: 'Poppins', sans-serif;
    }
    
    .container-contact .container-left h1 {
        font-size: 30px;
        font-weight: 500;
    }
    
    .container-contact .container-left p {
        width: 100%;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 1.5vw;
    }
    
    .container-contact .container-left label {
        font-size: 15px;
        font-weight: 600;
    }
    
    .container-contact .container-left input {
        width: 100%;
        height: 35px;
        padding: 10px;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #333;
        border: 1px solid #333;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.089);
    }
    
    .container-contact .container-left textarea {
        width: 100%;
        height: 120px;
        padding: 10px;
        font-size: 15px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #333;
        border: 1px solid #333;
        border-radius: 4px;
        resize: none;
        background-color: rgba(255, 255, 255, 0.089);
    }
    
    .container-contact .container-left .container-1 {
        width: 100%;
        height: auto;
        margin: .5vw 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .container-contact .container-left .container-1 .input-box {
        display: flex;
        flex-direction: column;
        width: 49%;
        height: auto;
    }
    
    .container-contact .container-left .container-2 {
        width: 100%;
        height: auto;
        margin: .5vw 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .container-contact .container-left .container-2 .input-box {
        display: flex;
        flex-direction: column;
        width: 49%;
        height: auto;
    }
    
    .container-contact .container-left .container-3 {
        width: 100%;
        height: auto;
        margin: 1vw 0;
        display: flex;
        flex-direction: column;
    }
    
    .container-contact .container-left .container-3 .input-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: .25vw 0;
        height: auto;
    }
    
    .container-contact .container-left .container-btn {
        width: 100%;
        margin-top: 2vw;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    .container-contact .container-left .container-btn button {
        font-size: 15px;
        font-weight: 600;
        padding: 10px 20px;
        background-color: #e1af56;
        color: #ffffff;
        border: none;
        border-radius: 6px;
        cursor: pointer;
    }
    
    .container-contact .container-left .container-btn button:hover {
        background-color: rgb(245, 194, 106);
    }
    
    .container-right {
        display: none;
    }
    
}

@media (min-width: 1200px) and (max-width: 1440px) {

    .background-contact {
        width: 100%;
        margin: 80px 0;
    }
    
    .container-contact {
        width: 100%;
        max-width: 1000px;
        padding: 50px 0px;
        height: auto;
        margin: 0 auto;
        color: #333;
        display: flex;
        flex-direction: row;
    }
    
    .container-contact .container-left {
        width: 50%;
        height: auto;
        font-family: 'Poppins', sans-serif;
    }
    
    .container-contact .container-left h1 {
        font-size: 30px;
        font-weight: 500;
    }
    
    .container-contact .container-left p {
        width: 100%;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 1.5vw;
    }
    
    .container-contact .container-left label {
        font-size: 15px;
        font-weight: 600;
    }
    
    .container-contact .container-left input {
        width: 100%;
        height: 35px;
        padding: 10px;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #333;
        border: 1px solid #333;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.089);
    }
    
    .container-contact .container-left textarea {
        width: 100%;
        height: 120px;
        padding: 10px;
        font-size: 15px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #333;
        border: 1px solid #333;
        border-radius: 4px;
        resize: none;
        background-color: rgba(255, 255, 255, 0.089);
    }
    
    .container-contact .container-left .container-1 {
        width: 100%;
        height: auto;
        margin: .5vw 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .container-contact .container-left .container-1 .input-box {
        display: flex;
        flex-direction: column;
        width: 49%;
        height: auto;
    }
    
    .container-contact .container-left .container-2 {
        width: 100%;
        height: auto;
        margin: .5vw 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .container-contact .container-left .container-2 .input-box {
        display: flex;
        flex-direction: column;
        width: 49%;
        height: auto;
    }
    
    .container-contact .container-left .container-3 {
        width: 100%;
        height: auto;
        margin: 1vw 0;
        display: flex;
        flex-direction: column;
    }
    
    .container-contact .container-left .container-3 .input-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: .25vw 0;
        height: auto;
    }
    
    .container-contact .container-left .container-btn {
        width: 100%;
        margin-top: 2vw;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    .container-contact .container-left .container-btn button {
        font-size: 15px;
        font-weight: 600;
        padding: 10px 20px;
        background-color: #e1af56;
        color: #ffffff;
        border: none;
        border-radius: 6px;
        cursor: pointer;
    }
    
    .container-contact .container-left .container-btn button:hover {
        background-color: rgb(245, 194, 106);
    }
    
    .container-right {
        width: 50%;
        height: auto;
        display: flex;
        justify-content: end;
        align-content: center;
    }
    
    .container-right img {
        width: 80%;
        height: auto;
        object-fit: contain;
    }
    
}

@media (min-width: 1440px) { 

    .background-contact {
        width: 100%;
        margin-bottom: 100px;
        background-color: rgb(235, 235, 235);
    }
    
    .container-contact {
        width: 100%;
        padding: 100px 60px;
        max-width: 1440px;
        height: auto;
        margin: 0 auto;
        color: #333;
        display: flex;
        flex-direction: row;
    }
    
    .container-contact .container-left {
        width: 50%;
        height: auto;
        font-family: 'Poppins', sans-serif;
    }
    
    .container-contact .container-left h1 {
        font-size: 30px;
        font-weight: 500;
    }
    
    .container-contact .container-left p {
        width: 65%;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 1.5vw;
    }
    
    .container-contact .container-left label {
        font-size: 15px;
        font-weight: 600;
    }
    
    .container-contact .container-left input {
        width: 100%;
        height: 35px;
        padding: 10px;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #333;
        border: 1px solid #333;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.089);
    }
    
    .container-contact .container-left textarea {
        width: 100%;
        height: 120px;
        padding: 10px;
        font-size: 15px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        outline: none;
        color: #333;
        border: 1px solid #333;
        border-radius: 4px;
        resize: none;
        background-color: rgba(255, 255, 255, 0.089);
    }
    
    .container-contact .container-left .container-1 {
        width: 100%;
        height: auto;
        margin: .5vw 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .container-contact .container-left .container-1 .input-box {
        display: flex;
        flex-direction: column;
        width: 49%;
        height: auto;
    }
    
    .container-contact .container-left .container-2 {
        width: 100%;
        height: auto;
        margin: .5vw 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .container-contact .container-left .container-2 .input-box {
        display: flex;
        flex-direction: column;
        width: 49%;
        height: auto;
    }
    
    .container-contact .container-left .container-3 {
        width: 100%;
        height: auto;
        margin: 1vw 0;
        display: flex;
        flex-direction: column;
    }
    
    .container-contact .container-left .container-3 .input-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: .25vw 0;
        height: auto;
    }
    
    .container-contact .container-left .container-btn {
        width: 100%;
        margin-top: 2vw;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    .container-contact .container-left .container-btn button {
        font-size: 15px;
        font-weight: 600;
        padding: 10px 20px;
        background-color: #e1af56;
        color: #ffffff;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
    
    .container-contact .container-left .container-btn button:hover {
        background-color: rgb(245, 194, 106);
    }    
    
    .container-right {
        width: 50%;
        height: auto;
        display: flex;
        justify-content: end;
        align-content: center;
    }
    
    .container-right img {
        width: 75%;
        height: auto;
        object-fit: contain;
    }
    
}
